<template>
  <backoffice-container>
    <b-card>
      <div v-if="isLoading" class="mb-5">
        <b-spinner
          type="grow"
          small
          variant="primary"
          class="mx-auto mt-3 d-block"
        />
      </div>
      <div v-else>
        <h4 class="my-1">
          {{ $t("backoffice.feedback.subtitle") }}
        </h4>

        <div v-if="suggestions.length > 0">
          <b-row class="todo-app-list mt-3">
            <b-col>
              <b-table
                class="position-relative"
                :items="suggestions"
                responsive
                :fields="fields"
                primary-key="id"
                show-empty
                empty-text="loading"
              >
                <!-- Column: Type -->
                <template #cell(type)="{ item }">
                  <b-media class="d-flex align-items-center">
                    <b-link
                      :to="getSuggestionLocation(item)"
                      class="text-dark d-flex"
                    >
                      <div class="ml-1">
                        <b-badge
                          pill
                          :variant="`light-${resolveTagVariant(item.type)}`"
                          class="text-capitalize"
                        >
                          {{ getTypeText(item.type) }}
                        </b-badge>
                      </div>
                    </b-link>
                  </b-media>
                </template>

                <!-- Column: Suggestion -->
                <template #cell(suggestion)="{ item }">
                  <b-link
                    :to="getSuggestionLocation(item)"
                    class="text-dark d-flex"
                  >
                    <div>
                      <p class="mb-0 ml-1 pl-25 truncated-text">
                        {{ item.message }}
                      </p>
                    </div>
                  </b-link>
                </template>

                <!-- Column: User -->
                <template #cell(user)="{ item }">
                  <b-link
                    :to="getSuggestionLocation(item)"
                    class="text-dark d-flex"
                  >
                    <div class="text-nowrap">
                      <p class="mb-0 ml-1 pl-25">
                        <b-avatar
                          v-b-tooltip.hover
                          :title="item.ownedByMember.name"
                          size="32"
                          :src="getImageSrc(item.ownedByMember.avatar_url)"
                          class="mr-50"
                        />
                        {{ item.ownedByMember.name }}
                        {{ item.ownedByMember.surname }}
                      </p>
                    </div>
                  </b-link>
                </template>
                <!-- Column: Where -->
                <template #cell(where)="{ item }">
                  <b-link
                    :to="getSuggestionLocation(item)"
                    class="text-dark d-flex"
                  >
                    <div>
                      <p class="mb-0 ml-1 pl-25 truncated-text">
                        {{ translate(item.ownedByCommunity.name) }}
                      </p>
                    </div>
                  </b-link>
                </template>
                <!-- Column: Status -->
                <template #cell(status)="{ item }">
                  <b-link
                    :to="getSuggestionLocation(item)"
                    class="text-dark d-flex"
                  >
                    <div class="text-nowrap">
                      <p class="mb-0">
                        <b-badge
                          pill
                          :variant="`${resolveStatusVariant(item.status)}`"
                          class="text-capitalize ml-1"
                        >
                          {{ getStatus(item.status) }}
                        </b-badge>
                      </p>
                    </div>
                  </b-link>
                </template>
              </b-table>
            </b-col>
          </b-row>
        </div>
        <b-row v-else class="horizontal-placeholder flex-column">
          <b-col cols="12">
            <img :src="placeholder" class="m-0 d-block mx-auto">
          </b-col>
          <b-col cols="12">
            <p class="text-primary">
              {{
                $t("backoffice.feedback.placeholder-text", {
                  item: $t("backoffice.feedback.title"),
                })
              }}
            </p>
          </b-col>
        </b-row>
      </div>
    </b-card>
  </backoffice-container>
</template>

<script>
import {
  BBadge,
  BAvatar,
  BCard,
  VBTooltip,
  BLink,
  BCol,
  BRow,
  BSpinner,
  BTable,
  BMedia,
} from 'bootstrap-vue';
import BackofficeContainer from '@/backoffice/components/BackofficeContainer.vue';
import { getImageResource } from '@/@core/utils/image-utils';
import Placeholder from '@/assets/images/placeholders/light/general-placeholder.svg';
import { translateTranslationTable } from '@/@core/libs/i18n/utils';

export default {
  name: 'FeedbackDetails',
  components: {
    BBadge,
    BAvatar,
    BackofficeContainer,
    BCard,
    BCol,
    BRow,
    BLink,
    BSpinner,
    BTable,
    BMedia,
  },
  directives: { BTooltip: VBTooltip },

  data() {
    return {
      isLoading: false,
    };
  },
  computed: {
    taskFilters() {
      return [
        {
          color: 'danger',
          title: this.$t('backoffice.feedback.type-options.bug'),
        },
        {
          color: 'warning',
          title: this.$t('backoffice.feedback.type-options.improvement'),
        },
        {
          color: 'info',
          title: this.$t('backoffice.feedback.type-options.query'),
        },
      ];
    },
    taskStatus() {
      return [
        { icon: 'ListIcon', title: this.$t('backoffice.feedback.status.all') },
        {
          icon: 'InboxIcon',
          title: this.$t('backoffice.feedback.status.to-do'),
        },
        {
          icon: 'EditIcon',
          title: this.$t('backoffice.feedback.status.in-progress'),
        },
        {
          icon: 'EyeIcon',
          title: this.$t('backoffice.feedback.status.in-review'),
        },
        {
          icon: 'CheckSquareIcon',
          title: this.$t('backoffice.feedback.status.done'),
        },
      ];
    },
    fields() {
      return [
        {
          key: 'suggestion',
          label: this.$t('backoffice.feedback.name'),
        },
        {
          key: 'where',
          label: this.$t('backoffice.feedback.where'),
        },
        {
          key: 'type',
          label: this.$t('backoffice.feedback.type'),
        },
        {
          key: 'user',
          label: this.$t('backoffice.feedback.author'),
        },

        {
          key: 'status',
          label: this.$t('backoffice.feedback.status-title'),
        },
      ];
    },
    suggestions() {
      return this.$store.getters.feedback.unpaginated;
    },
    perfectScrollbarSettings() {
      return { maxScrollbarLength: 150 };
    },
    placeholder() {
      return Placeholder;
    },
    item() {
      return this.$t('backoffice.feedback.title');
    },
  },
  async created() {
    await this.fetchData();
  },
  methods: {
    translate(field) {
      return translateTranslationTable(this.$store.state.locale.currentLocale, field);
    },
    resolveTagVariant(tag) {
      if (tag === 'bug') {
        return 'danger';
      }
      if (tag === 'feature_improvement') {
        return 'warning';
      }
      return 'info';
    },
    formatDate(date) {
      const d = new Date(date);
      return d.toLocaleString('en-GB');
    },
    async fetchData() {
      this.isLoading = true;
      await this.$store.dispatch('getItems', {
        itemType: 'backoffice/feedback',
        customName: 'feedback',
        page: 1,
      });
      this.isLoading = false;
    },
    getSuggestionLocation(task) {
      return {
        name: 'feedback-details',
        params: { id: task.key, communityId: this.$route.params.communityId },
      };
    },
    getTypeText(tag) {
      return tag === 'bug'
        ? this.$t('backoffice.feedback.type-options.bug')
        : tag === 'feature_improvement'
          ? this.$t('backoffice.feedback.type-options.improvement')
          : this.$t('backoffice.feedback.type-options.query');
    },
    handleFilter(filter) {
      if (filter.title === this.$t('backoffice.feedback.status.all')) {
        this.suggestions = this.$store.getters.feedback;
      }
      if (filter.title === this.$t('backoffice.feedback.status.to-do')) {
        this.suggestions = this.suggestions.filter(
          (item) => item.status === 'To do',
        );
      }
      if (filter.title === this.$t('backoffice.feedback.status.in-review')) {
        this.suggestions = this.suggestions.filter(
          (item) => item.status === 'In review',
        );
      }
    },
    resolveStatusVariant(status) {
      if (status === 'To do') {
        return 'success';
      }
      if (status === 'In review') {
        return 'warning';
      }
      if (status === 'In progress') {
        return 'primary';
      }
      return 'secondary';
    },
    getImageSrc(url) {
      return getImageResource(url);
    },
    getStatus(status) {
      const statusTranslated = {
        'To do': this.$t('backoffice.feedback.status.to-do'),
        'In progress': this.$t('backoffice.feedback.status.in-progress'),
        'In review': this.$t('backoffice.feedback.status.in-review'),
        Done: this.$t('backoffice.feedback.status.done'),
      };
      return statusTranslated[status];
    },
  },
};
</script>

<style lang="scss" scoped>
.draggable-task-handle {
  position: absolute;
  left: 8px;
  top: 50%;
  transform: translateY(-50%);
  visibility: hidden;
  cursor: move;

  .todo-task-list .todo-item:hover & {
    visibility: visible;
  }
}
.truncated-text {
  white-space: nowrap;
  text-overflow: ellipsis;
  overflow: hidden;
  max-width: 450px;
  min-width: 0;
}
</style>

<style lang="scss">
@import "~@core/scss/base/pages/app-todo.scss";
</style>
