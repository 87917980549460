var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('backoffice-container',[_c('b-card',[(_vm.isLoading)?_c('div',{staticClass:"mb-5"},[_c('b-spinner',{staticClass:"mx-auto mt-3 d-block",attrs:{"type":"grow","small":"","variant":"primary"}})],1):_c('div',[_c('h4',{staticClass:"my-1"},[_vm._v(" "+_vm._s(_vm.$t("backoffice.feedback.subtitle"))+" ")]),(_vm.suggestions.length > 0)?_c('div',[_c('b-row',{staticClass:"todo-app-list mt-3"},[_c('b-col',[_c('b-table',{staticClass:"position-relative",attrs:{"items":_vm.suggestions,"responsive":"","fields":_vm.fields,"primary-key":"id","show-empty":"","empty-text":"loading"},scopedSlots:_vm._u([{key:"cell(type)",fn:function(ref){
var item = ref.item;
return [_c('b-media',{staticClass:"d-flex align-items-center"},[_c('b-link',{staticClass:"text-dark d-flex",attrs:{"to":_vm.getSuggestionLocation(item)}},[_c('div',{staticClass:"ml-1"},[_c('b-badge',{staticClass:"text-capitalize",attrs:{"pill":"","variant":("light-" + (_vm.resolveTagVariant(item.type)))}},[_vm._v(" "+_vm._s(_vm.getTypeText(item.type))+" ")])],1)])],1)]}},{key:"cell(suggestion)",fn:function(ref){
var item = ref.item;
return [_c('b-link',{staticClass:"text-dark d-flex",attrs:{"to":_vm.getSuggestionLocation(item)}},[_c('div',[_c('p',{staticClass:"mb-0 ml-1 pl-25 truncated-text"},[_vm._v(" "+_vm._s(item.message)+" ")])])])]}},{key:"cell(user)",fn:function(ref){
var item = ref.item;
return [_c('b-link',{staticClass:"text-dark d-flex",attrs:{"to":_vm.getSuggestionLocation(item)}},[_c('div',{staticClass:"text-nowrap"},[_c('p',{staticClass:"mb-0 ml-1 pl-25"},[_c('b-avatar',{directives:[{name:"b-tooltip",rawName:"v-b-tooltip.hover",modifiers:{"hover":true}}],staticClass:"mr-50",attrs:{"title":item.ownedByMember.name,"size":"32","src":_vm.getImageSrc(item.ownedByMember.avatar_url)}}),_vm._v(" "+_vm._s(item.ownedByMember.name)+" "+_vm._s(item.ownedByMember.surname)+" ")],1)])])]}},{key:"cell(where)",fn:function(ref){
var item = ref.item;
return [_c('b-link',{staticClass:"text-dark d-flex",attrs:{"to":_vm.getSuggestionLocation(item)}},[_c('div',[_c('p',{staticClass:"mb-0 ml-1 pl-25 truncated-text"},[_vm._v(" "+_vm._s(_vm.translate(item.ownedByCommunity.name))+" ")])])])]}},{key:"cell(status)",fn:function(ref){
var item = ref.item;
return [_c('b-link',{staticClass:"text-dark d-flex",attrs:{"to":_vm.getSuggestionLocation(item)}},[_c('div',{staticClass:"text-nowrap"},[_c('p',{staticClass:"mb-0"},[_c('b-badge',{staticClass:"text-capitalize ml-1",attrs:{"pill":"","variant":("" + (_vm.resolveStatusVariant(item.status)))}},[_vm._v(" "+_vm._s(_vm.getStatus(item.status))+" ")])],1)])])]}}],null,false,52771085)})],1)],1)],1):_c('b-row',{staticClass:"horizontal-placeholder flex-column"},[_c('b-col',{attrs:{"cols":"12"}},[_c('img',{staticClass:"m-0 d-block mx-auto",attrs:{"src":_vm.placeholder}})]),_c('b-col',{attrs:{"cols":"12"}},[_c('p',{staticClass:"text-primary"},[_vm._v(" "+_vm._s(_vm.$t("backoffice.feedback.placeholder-text", { item: _vm.$t("backoffice.feedback.title"), }))+" ")])])],1)],1)])],1)}
var staticRenderFns = []

export { render, staticRenderFns }